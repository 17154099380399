<template>
    <div>
        <h2>ช่องทางการชำระ</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-text-field
              outlined
              v-model="datatable.search"
              placeholder="ค้นหา ..."
              dense
            ></v-text-field>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <!--
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
            -->
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'pospayment_create', params: { pospayment_id: 'create' } })">เพิ่มช่องทาง</v-btn>
          </div>
          <delay-datatable
            :headers="datatable.headers"
            :url="'/possetting/payment/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template          
            v-slot:item.type="{ item }"
          >
            {{ helpers.convertPOSPaymentTypeToText(item.type) }}               
          </template>
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'pospayment_create', params: { pospayment_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'POSSetting') ? icons.mdiPlaylistEdit : icons.mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'POSSetting') || item.count_devices == 0"
            ><v-icon>{{ icons.mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>                
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { convertPOSPaymentTypeToText } from '@/helpers/converter.js'
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อช่องทาง',
            value: 'name',
          },
          {
            text: 'ประเภท',
            value: 'type',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            //align: 'center',
          },
        ],
      },
      icons: {
        mdiDeleteOutline,
        mdiPlaylistEdit,
        mdiEyeOutline,
      },
      helpers: {
        convertPOSPaymentTypeToText,
      },
      validators: {
        required,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    async deleteMultiple() {
      const paymentNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const paymentIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + paymentNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/possetting/payment', { items: paymentIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/possetting/payment', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>